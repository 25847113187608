// import { useDispatch, useSelector } from "react-redux";
// import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

// import { setCourse, setEditCourse } from "../../../../slices/courseSlice";
// import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
// import { useState } from "react";
// import { FaCheck } from "react-icons/fa";
// import { FiEdit2 } from "react-icons/fi";
// import { HiClock } from "react-icons/hi";
// import { RiDeleteBin6Line } from "react-icons/ri";
// import { useNavigate } from "react-router-dom";

// import { formatDate } from "../../../../services/formatDate";
// import {
//   deleteCourse,
//   fetchInstructorCourses,
// } from "../../../../services/operations/courseDetailsAPI";
// import { COURSE_STATUS } from "../../../../utils/constants";
// import ConfirmationModal from "../../../common/ConfirmationModal";

// export default function CoursesTable({ courses, setCourses }) {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { token } = useSelector((state) => state.auth);
//   const [loading, setLoading] = useState(false);
//   const [confirmationModal, setConfirmationModal] = useState(null);
//   const TRUNCATE_LENGTH = 30;

//   const handleCourseDelete = async (courseId) => {
//     setLoading(true);
//     await deleteCourse({ courseId: courseId }, token);
//     const result = await fetchInstructorCourses(token);
//     if (result) {
//       setCourses(result);
//     }
//     setConfirmationModal(null);
//     setLoading(false);
//   };

//   // console.log("All Course ", courses)

//   return (
//     <>
//       <Table className="rounded-xl border border-black ">
//         <Thead>
//           <Tr className="flex gap-x-10 rounded-t-md border-b border-b-black px-6 py-2">
//             <Th className="flex-1 text-left text-sm font-medium uppercase text-black">
//               Courses
//             </Th>
//             <Th className="text-left text-sm font-medium uppercase text-black">
//               Duration
//             </Th>
//             <Th className="text-left text-sm font-medium uppercase text-black">
//               Price
//             </Th>
//             <Th className="text-left text-sm font-medium uppercase text-black">
//               Actions
//             </Th>
//           </Tr>
//         </Thead>
//         <Tbody>
//           {courses?.length === 0 ? (
//             <Tr>
//               <Td className="py-10 text-center text-2xl font-medium text-black">
//                 No courses found
//                 {/* TODO: Need to change this state */}
//               </Td>
//             </Tr>
//           ) : (
//             courses?.map((course) => (
//               <Tr
//                 key={course._id}
//                 className="flex gap-x-10 border-b border-black px-6 py-8"
//               >
//                 <Td className="flex flex-1 gap-x-4">
//                   <img
//                     src={course?.thumbnail}
//                     alt={course?.courseName}
//                     className="h-[148px] w-[220px] rounded-lg object-cover"
//                   />
//                   <div className="flex flex-col justify-between">
//                     <p className="text-lg font-semibold text-black">
//                       {course.courseName}
//                     </p>
//                     <p className="text-xs text-black">
//                       {course.courseDescription.split(" ").length >
//                       TRUNCATE_LENGTH
//                         ? course.courseDescription
//                             .split(" ")
//                             .slice(0, TRUNCATE_LENGTH)
//                             .join(" ") + "..."
//                         : course.courseDescription}
//                     </p>
//                     <p className="text-[12px] text-white">
//                       Created: {formatDate(course.createdAt)}
//                     </p>
//                     {course.status === COURSE_STATUS.DRAFT ? (
//                       <p className="flex w-fit flex-row items-center gap-2 rounded-full bg-richblack-700 px-2 py-[2px] text-[12px] font-medium text-pink-100">
//                         <HiClock size={14} />
//                         Drafted
//                       </p>
//                     ) : (
//                       <p className="flex w-fit flex-row items-center gap-2 rounded-full bg-richblack-700 px-2 py-[2px] text-[12px] font-medium text-yellow-100">
//                         <div className="flex h-3 w-3 items-center justify-center rounded-full bg-yellow-100 text-black">
//                           <FaCheck size={8} />
//                         </div>
//                         Published
//                       </p>
//                     )}
//                   </div>
//                 </Td>
//                 <Td className="text-sm font-medium text-black">
//                   {course?.totalDuration}
//                 </Td>
//                 <Td className="text-sm font-medium text-black">
//                   ₹{course.price}
//                 </Td>
//                 <Td className="text-sm font-medium text-black ">
//                   <button
//                     disabled={loading}
//                     onClick={() => {
//                       navigate(`/dashboard/edit-course/${course._id}`);
//                     }}
//                     title="Edit"
//                     className="px-2 transition-all duration-200 hover:scale-110 hover:text-caribbeangreen-300"
//                   >
//                     <FiEdit2 size={20} />
//                   </button>
//                   <button
//                     disabled={loading}
//                     onClick={() => {
//                       setConfirmationModal({
//                         text1: "Do you want to delete this course?",
//                         text2:
//                           "All the data related to this course will be deleted",
//                         btn1Text: !loading ? "Delete" : "Loading...  ",
//                         btn2Text: "Cancel",
//                         btn1Handler: !loading
//                           ? () => handleCourseDelete(course._id)
//                           : () => {},
//                         btn2Handler: !loading
//                           ? () => setConfirmationModal(null)
//                           : () => {},
//                       });
//                     }}
//                     title="Delete"
//                     className="px-1 transition-all duration-200 hover:scale-110 hover:text-[#ff0000]"
//                   >
//                     <RiDeleteBin6Line size={20} />
//                   </button>
//                 </Td>
//               </Tr>
//             ))
//           )}
//         </Tbody>
//       </Table>
//       {confirmationModal && <ConfirmationModal modalData={confirmationModal} />}
//     </>
//   );
// }

import { useDispatch, useSelector } from "react-redux";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import { HiClock } from "react-icons/hi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../../services/formatDate";
import {
  deleteCourse,
  fetchInstructorCourses,
} from "../../../../services/operations/courseDetailsAPI";
import { COURSE_STATUS } from "../../../../utils/constants";
import ConfirmationModal from "../../../common/ConfirmationModal";

export default function CoursesTable({ courses, setCourses }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const TRUNCATE_LENGTH = 30;

  // Function to calculate total course duration from subsections
  const calculateCourseDuration = (course) => {
    if (course?.totalDuration) {
      return course.totalDuration;
    }
    return "0 min";
  };

  const handleCourseDelete = async (courseId) => {
    setLoading(true);
    await deleteCourse({ courseId: courseId }, token);
    const result = await fetchInstructorCourses(token);
    if (result) {
      setCourses(result);
    }
    setConfirmationModal(null);
    setLoading(false);
  };

  return (
    <>
      <Table className="rounded-xl border border-black ">
        <Thead>
          <Tr className="flex gap-x-10 rounded-t-md border-b border-b-black px-6 py-2">
            <Th className="flex-1 text-left text-sm font-medium uppercase text-black">
              Courses
            </Th>
            <Th className="text-left text-sm font-medium uppercase text-black">
              Duration
            </Th>
            <Th className="text-left text-sm font-medium uppercase text-black">
              Price
            </Th>
            <Th className="text-left text-sm font-medium uppercase text-black">
              Actions
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {courses?.length === 0 ? (
            <Tr>
              <Td className="py-10 text-center text-2xl font-medium text-black">
                No courses found
              </Td>
            </Tr>
          ) : (
            courses?.map((course) => (
              <Tr
                key={course._id}
                className="flex gap-x-10 border-b border-black px-6 py-8"
              >
                <Td className="flex flex-1 gap-x-4">
                  <img
                    src={course?.thumbnail}
                    alt={course?.courseName}
                    className="h-[148px] w-[220px] rounded-lg object-cover"
                  />
                  <div className="flex flex-col justify-between">
                    <p className="text-lg font-semibold text-black">
                      {course.courseName}
                    </p>
                    <p className="text-xs text-black">
                      {course.courseDescription.split(" ").length >
                      TRUNCATE_LENGTH
                        ? course.courseDescription
                            .split(" ")
                            .slice(0, TRUNCATE_LENGTH)
                            .join(" ") + "..."
                        : course.courseDescription}
                    </p>
                    <p className="text-[12px] text-white">
                      Created: {formatDate(course.createdAt)}
                    </p>
                    {course.status === COURSE_STATUS.DRAFT ? (
                      <p className="flex w-fit flex-row items-center gap-2 rounded-full bg-richblack-700 px-2 py-[2px] text-[12px] font-medium text-pink-100">
                        <HiClock size={14} />
                        Drafted
                      </p>
                    ) : (
                      <p className="flex w-fit flex-row items-center gap-2 rounded-full bg-richblack-700 px-2 py-[2px] text-[12px] font-medium text-yellow-100">
                        <div className="flex h-3 w-3 items-center justify-center rounded-full bg-yellow-100 text-black">
                          <FaCheck size={8} />
                        </div>
                        Published
                      </p>
                    )}
                  </div>
                </Td>
                <Td className="text-sm font-medium text-black">
                  {calculateCourseDuration(course)}
                </Td>
                <Td className="text-sm font-medium text-black">
                  ₹{course.price}
                </Td>
                <Td className="text-sm font-medium text-black ">
                  <button
                    disabled={loading}
                    onClick={() =>
                      navigate(`/dashboard/edit-course/${course._id}`)
                    }
                    title="Edit"
                    className="px-2 transition-all duration-200 hover:scale-110 hover:text-caribbeangreen-300"
                  >
                    <FiEdit2 size={20} />
                  </button>
                  <button
                    disabled={loading}
                    onClick={() => {
                      setConfirmationModal({
                        text1: "Do you want to delete this course?",
                        text2:
                          "All the data related to this course will be deleted",
                        btn1Text: !loading ? "Delete" : "Loading...",
                        btn2Text: "Cancel",
                        btn1Handler: !loading
                          ? () => handleCourseDelete(course._id)
                          : () => {},
                        btn2Handler: !loading
                          ? () => setConfirmationModal(null)
                          : () => {},
                      });
                    }}
                    title="Delete"
                    className="px-1 transition-all duration-200 hover:scale-110 hover:text-[#ff0000]"
                  >
                    <RiDeleteBin6Line size={20} />
                  </button>
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
      {confirmationModal && <ConfirmationModal modalData={confirmationModal} />}
    </>
  );
}
